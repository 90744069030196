<!-- eslint-disable -->
<template>
  <div class="table-responsive text-nowrap">
    <a-table :columns="columns" :data-source="data" :rowKey="email" :loading="tableLoading">
      <template
        #filterDropdown="{
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column,
        }"
      >
        <div class="custom-filter-dropdown">
          <a-input
            :placeholder="`Search ${column.dataIndex}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="() => handleSearch(selectedKeys, confirm)"
          />
          <a-button
            type="primary"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="() => handleSearch(selectedKeys, confirm)"
            >Search</a-button
          >
          <a-button size="small" style="width: 90px" @click="() => handleReset(clearFilters)"
            >Reset</a-button
          >
        </div>
      </template>
      <template #filterIcon="{ text: filtered }">
        <a-icon type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
      </template>
      <template #utilisateur="{ text }">
        <span v-if="searchText">
          <template
            v-for="(fragment, i) in text
              .toString()
              .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
          >
            <mark
              v-if="fragment.toLowerCase() === searchText.toLowerCase()"
              :key="i"
              class="highlight"
              >{{ fragment }}</mark
            >
            <template v-else>{{ fragment }}</template>
          </template>
        </span>
        <template v-else>
          <a class="btn btn-sm btn-light" href="javascript: void(0);">{{ text }}</a>
        </template>
      </template>   
      <template #title="{ text }">
        <span>{{ text }}</span>
      </template>
      <template #seen="{ text }">
        <span>{{ text }}</span>
      </template>
      <template #status="{ text }">
        <span
          :class="[
            text === 'inactive'
              ? 'font-size-12 badge badge-primary'
              : 'font-size-12 badge badge-success',
          ]"
          >{{ text === 'inactive'?'Inactive' :"Active"}}</span
        >
      </template>
      <template #action={record}>
        <span>
          <a-button  @click="showAccess(record)" class="btn btn-sm btn-light mr-2" :disabled="disableBtn(record.email)"> 
            <i class="fe fe-edit mr-2" />
            Contrôle d'accès
          </a-button>
          <a-button @click="suppUser(record)" class="btn btn-sm btn-light" :disabled="disableBtn(record.email)">
            <small>
              <i class="fe fe-trash mr-2" />
            </small>
            {{record.status=='active'?'Désactiver':"Activer"}}
          </a-button>
        </span>
      </template>
    </a-table>
    <a-modal
      v-model:visible="visible"
      :width="400"
      :title="`Contrôle d'accès pour ${modelInfo.fName} ${modelInfo.lName}`"
       @ok="ChangeAccess"
    >
    <div class="row">
      <div class="col-md-6">
        Admin
              </div>      
        <div class="col-md-3">
        <a-switch v-model:checked="access.admin">
          <template #checkedChildren><check-outlined /></template>
          <template #unCheckedChildren><close-outlined /></template>
        </a-switch>        
        </div>
      <div class="col-md-6">
        Acceuil
              </div>
        <div class="col-md-3">
        <a-switch v-model:checked="access.Acceuil">
          <template #checkedChildren><check-outlined /></template>
          <template #unCheckedChildren><close-outlined /></template>
        </a-switch>        
        </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        Comptes
              </div>
        <div class="col-md-3">
        <a-switch v-model:checked="access.Comptes">
          <template #checkedChildren><check-outlined /></template>
          <template #unCheckedChildren><close-outlined /></template>
        </a-switch>        
        </div>
      <div class="col-md-6">
        Publications
              </div>
        <div class="col-md-3">
        <a-switch v-model:checked="access.publications">
          <template #checkedChildren><check-outlined /></template>
          <template #unCheckedChildren><close-outlined /></template>
        </a-switch>        
        </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        actualités
            </div>
        <div class="col-md-3">
        <a-switch v-model:checked="access.actualite">
          <template #checkedChildren><check-outlined /></template>
          <template #unCheckedChildren><close-outlined /></template>
        </a-switch>        
        </div>
      <div class="col-md-6">
        Débats
              </div>
        <div class="col-md-3">
    <a-switch v-model:checked="access.debats">
      <template #checkedChildren><check-outlined /></template>
      <template #unCheckedChildren><close-outlined /></template>
    </a-switch>        
    </div>
      </div>
      <div class="row">
      <div class="col-md-6">
        Agendas
              </div>
        <div class="col-md-3">
    <a-switch v-model:checked="access.agendas">
      <template #checkedChildren><check-outlined /></template>
      <template #unCheckedChildren><close-outlined /></template>
    </a-switch>        
    </div>
      <div class="col-md-6">
        Contact
              </div>
        <div class="col-md-3">
    <a-switch v-model:checked="access.contact">
      <template #checkedChildren><check-outlined /></template>
      <template #unCheckedChildren><close-outlined /></template>
    </a-switch>        
    </div>
      </div>
      <div class="row">
      <div class="col-md-6">
        Messages
              </div>
        <div class="col-md-3">
    <a-switch v-model:checked="access.message">
      <template #checkedChildren><check-outlined /></template>
      <template #unCheckedChildren><close-outlined /></template>
    </a-switch>        
    </div>
      <div class="col-md-6">
        Inscrit
              </div>
        <div class="col-md-3">
    <a-switch v-model:checked="access.inscrit">
      <template #checkedChildren><check-outlined /></template>
      <template #unCheckedChildren><close-outlined /></template>
    </a-switch>        
    </div>
    </div>    
    </a-modal>
  </div>
</template>

<script>
/* eslint-disable */
import ApiClient from "@/services/axios"
import { message } from 'ant-design-vue';
import { mapState } from "vuex";
export default {
  computed: mapState(["user"]),
  props:{
    data:{
      type:Array
    },
    tableLoading:{
      type:Boolean
    }
  },
  emits: ['changeData'],
  data(){
    return {
      visible:false,
      access:{
        admin:false,
        Acceuil: false,
        Comptes: false,
        publications: false,
        actualite: false,
        debats:false,
        agendas: false,
        contact: false,
        adherents: false,
        inscrit: false,
      },
      modelInfo:{
        _id:"",
        email: "",
        fName:"",
        lName:"",
        phone:"",
        status: "",
        avatar: "",
      },
      columns:[        
        {
          title: 'Prénom',
          dataIndex: 'fName',
          key: 'fName',
          slots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'fName',
          },
          sorter: (a, b) => a.fName - b.fName,
          onFilter: (value, record) => record.fName.toLowerCase().includes(value.toLowerCase()),
        },
        {
          title: 'Nom',
          dataIndex: 'lName',
          key: 'lName',
          slots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'lName',
          },
          sorter: (a, b) => a.lName - b.lName,
          onFilter: (value, record) => record.lName.toLowerCase().includes(value.toLowerCase()),
        },        
        {
          title: 'Téléphone',
          dataIndex: 'phone',
          key: 'phone',
          slots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'phone',
          }, 
          sorter: (a, b) => a.phone - b.phone,
          onFilter: (value, record) => String(record.phone).toLowerCase().includes(value.toLowerCase()),                   
        },
        {
          title: 'E-mail',
          dataIndex: 'email',
          key: 'email',
          slots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'email',
          }, 
          sorter: (a, b) => a.email - b.email,
          onFilter: (value, record) => record.email.toLowerCase().includes(value.toLowerCase()),                   
        },        
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          sorter: (a, b) => a.status.length - b.status.length,
          slots: { customRender: 'status' },
        },
        {
          title: 'Action',
          slots: { customRender: 'action' },
        },
      ],
      searchText:'',
      searchInput:null,
    }
  },
  methods: {
    disableBtn(email){ 
      return email == this.user.email
    },
    showAccess(record){
      this.modelInfo = record
      console.log("1.", this.access)
      this.access = {...this.access,...record.access}
      console.log("2.", this.access)
      this.visible = true
    },
    ChangeAccess(){
      let record = this.modelInfo
      ApiClient.patch('/users/'+record._id,{data:{access:this.access}})
      .then(()=>{
        this.$emit("changeData",[...this.data.map((elem)=>elem._id == record._id?{...elem,access:this.access}:elem)])
        message.success(`L'access est modifié`)
        this.visible = false
      })
      .catch((e)=>{
        message.warning("Impossible de modifier l'access pour ce l'utilisateur")
      }) 
    },
    suppUser(record){
      const updateData = {status:record.status=='active'?"inactive":'active'}
      ApiClient.patch('/users/'+record._id,{
        data:updateData
      }).then(()=>{
        this.$emit("changeData",[...this.data.map((elem)=>elem._id == record._id?{...elem,status:record.status=='active'?"inactive":'active'}:elem)]        )
        message.success(`L'utilisateur est ${record.status=='active'?"désactivé":'activé'}`)
      })
      .catch((e)=>{
        message.warning("Impossible d'activer/désactiver l'utilisateur")
      })  
      },
    handleReset(){
      clearFilters(clearFilters)
      searchText.value = ''
    },
    handleSearch (selectedKeys, confirm){
      confirm()
      searchText.value = selectedKeys[0]
    }
  },
}
</script>

<style scoped>
.custom-filter-dropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
</style>
