<template>
  <a-modal
    v-model:visible="visibleModal"
    title="Ajouter Utilisateur"
    :width="800"
    ok-text="Ajouter"
    @ok="onSubmit"
  >
    <a-form
      ref="formRef"
      :model="formState"
      label-align="left"
      class="container"
      :label-col="{ span: 10 }"
      :wrapper-col="{ span: 12 }"
      :rules="rules"
      @finishFailed="handleFinishFailed"
    >
      <div class="row">
        <div class="col md-6">
          <a-form-item label="Nom" name="fName">
            <a-input v-model:value="formState.fName" placeholder="Nom" />
          </a-form-item>
          <a-form-item label="Prénom" name="lName">
            <a-input v-model:value="formState.lName" placeholder="Prénom" />
          </a-form-item>
          <a-form-item label="Email" name="email">
            <a-input v-model:value="formState.email" placeholder="Email" />
          </a-form-item>
          <a-form-item label="Mot de pass" name="password">
            <a-input-password v-model:value="formState.password" placeholder="Mot de pass" />
          </a-form-item>
          <a-form-item label="Téléphone" name="phone">
            <a-input-number v-model:value="formState.phone" placeholder="Téléphone" />
          </a-form-item>
        </div>
        <div class="col-md-6 container">
          <div class="row">
            <div class="col-md-6">
              <a-form-item label="Admin">
                <a-switch v-model:checked="formState.access.admin">
                  <template #checkedChildren><check-outlined /></template>
                  <template #unCheckedChildren><close-outlined /></template>
                </a-switch>
              </a-form-item>
            </div>
            <div class="col-md-6">
              <a-form-item label="Acceuil">
                <a-switch v-model:checked="formState.access.Acceuil">
                  <template #checkedChildren><check-outlined /></template>
                  <template #unCheckedChildren><close-outlined /></template>
                </a-switch>
              </a-form-item>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <a-form-item label="Comptes">
                <a-switch v-model:checked="formState.access.Comptes">
                  <template #checkedChildren><check-outlined /></template>
                  <template #unCheckedChildren><close-outlined /></template>
                </a-switch>
              </a-form-item>
            </div>
            <div class="col-md-6">
              <a-form-item label="Publications">
                <a-switch v-model:checked="formState.access.publications">
                  <template #checkedChildren><check-outlined /></template>
                  <template #unCheckedChildren><close-outlined /></template>
                </a-switch>
              </a-form-item>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <a-form-item label="Actualite">
                <a-switch v-model:checked="formState.access.actualite">
                  <template #checkedChildren><check-outlined /></template>
                  <template #unCheckedChildren><close-outlined /></template>
                </a-switch>
              </a-form-item>
            </div>
            <div class="col-md-6">
              <a-form-item label="Débats">
                <a-switch v-model:checked="formState.access.debats">
                  <template #checkedChildren><check-outlined /></template>
                  <template #unCheckedChildren><close-outlined /></template>
                </a-switch>
              </a-form-item>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <a-form-item label="Contact">
                <a-switch v-model:checked="formState.access.contact">
                  <template #checkedChildren><check-outlined /></template>
                  <template #unCheckedChildren><close-outlined /></template>
                </a-switch>
              </a-form-item>
            </div>
            <div class="col-md-6">
              <a-form-item label="Messages">
                <a-switch v-model:checked="formState.access.messages">
                  <template #checkedChildren><check-outlined /></template>
                  <template #unCheckedChildren><close-outlined /></template>
                </a-switch>
              </a-form-item>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <a-form-item label="inscrit">
                <a-switch v-model:checked="formState.access.inscrit">
                  <template #checkedChildren><check-outlined /></template>
                  <template #unCheckedChildren><close-outlined /></template>
                </a-switch>
              </a-form-item>
            </div>
            <div class="col-md-6"></div>
          </div>
        </div>
      </div>
    </a-form>
  </a-modal>
</template>
<script>
/* eslint-disable */
import { toRaw, ref, reactive, watch } from 'vue'
import { message } from 'ant-design-vue'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons-vue'

import ApiClient from '@/services/axios'

export default {
  components: {
    CheckOutlined,
    CloseOutlined,
  },
  props: ['visible'],
  setup(props) {
    const visibleModal = ref(props.visible)
    const formRef = ref()
    watch(
      () => props.visible,
      (newVal, oldVal) => {
        visibleModal.value = newVal
      },
    )
    const rules = {
      fName: [
        {
          required: true,
          message: 'Ce champs est requis',
          trigger: 'blur',
        },
      ],
      lName: [
        {
          required: true,
          message: 'Ce champs est requis',
          trigger: 'blur',
        },
      ],
      email: [
        {
          required: true,
          message: 'Ce champs est requis',
          trigger: 'blur',
          type: 'email',
        },
      ],
      phone: [
        {
          type: 'number',
          required: true,
          message: 'Ce champs est requis et doit etre un nombre',
          trigger: 'blur',
        },
      ],
      password: [
        {
          required: true,
          message: 'Ce champs est requis',
          trigger: 'blur',
        },
        {
          min: 6,
          message: 'Mot de passe faible !',
          trigger: 'blur',
        },
      ],
    }
    const formState = reactive({
      fName: '',
      lName: '',
      email: '',
      password: '',
      phone: null,
      access: {
        admin: false,
        Acceuil: false,
        Comptes: false,
        publications: false,
        add_publication: false,
        actualite: false,
        debats: false,
        agendas: false,
        contact: false,
        messages: false,
        inscrit: false,
      },
    })
    const handleFinishFailed = (errors) => {
      console.log(errors)
    }
    return {
      rules,
      formRef,
      formState,
      visibleModal,
      handleFinishFailed,
    }
  },
  methods: {
    onSubmit() {
      this.formRef
        .validate()
        .then(() => {
          const data = toRaw(this.formState)
          ApiClient.post('/users/create', data)
            .then((res) => {
              this.$emit('addedUser', res.data)
              message.success(`Utilisateur ajouté`)
            })
            .catch((e) => {
              message.warning("Impossible de ajouter l'utilisateur")
            })
        })
        .catch((error) => {
          console.log('error', error)
        })
    },
  },
}
</script>
