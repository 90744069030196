<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card-placeholder">
          <div class="card-header py-0">
            <vb-headers-card-header-3 :data="{ title: 'Gérer les comptes' }" />
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <vb-controls-button-2
              :data="{ title: 'Ajouter utilisateur', icon: 'fe fe-plus-circle', type: 'primary' }"
              @click="addUser"
            />
          </div>
          <div class="card-body">
            <accounts-table 
              :data="data" 
              :table-loading="tableLoading"
              @changeData="changeData"
              />
          </div>
        </div>
      </div>
    </div>
    <add-user-form 
    :visible="visible"
    @addedUser="addedUser"
    />
  </div>
</template>

<script>
/* eslint-disable */
import VbHeadersCardHeader3 from '@/@vb/widgets/Headers/CardHeader3'
import VbControlsButton2 from '@/@vb/widgets/Controls/Button2'
import ApiClient from "@/services/axios"
import accountsTable from '@/components/widgets/TablesAntd/accounts'
import addUserForm from '@/components/widgets/Forms/addUserForm'

export default {
  created() {
    this.tableLoading=true
    ApiClient.post('/users/filter',{
      query:{},
      aggregation:[
        {
          $project:
          {
            hash:0,
            salt:0,
          }
        }
      ]
    }).then((res)=>{
      this.data = res.data
    }).finally(()=>{
      this.tableLoading=false
    })
  },  
  name: 'VbComtpes',
  components: {
    VbHeadersCardHeader3,
    VbControlsButton2,
    accountsTable,
    addUserForm
  },
  data(){
    return {
      data:[],
      tableLoading:false,
      visible:false
    }
  },
  methods:{
    changeData(newData){this.data = newData},
    addUser(){this.visible=true},
    addedUser(newUser){this.data.push(newUser);this.visible=false}
  }
}
</script>
